import axios from 'axios';
import config from './Config';

export default class Apicall {
    return = {
        status: 'OK',
        data: null,
        msg: null,
    };

    domain = 'https://guide-nolinor-77uzsgbt3q-nn.a.run.app/'

    header = {};

    constructor(type = 'BETA1') {
        if (type === 'BETA2') {
            this.domain = 'https://guide-nolinor-beta2-77uzsgbt3q-nn.a.run.app/';
        }
    }

    async getHeaders() {
        this.header = {
            headers: {
                'Accept-Language': 'fr',
                Accept: 'application/json',
            },
        };
        return this.header;
    }

    async call(method: string, path: string, postdata?: any) {
        const self = this;
        const domain = config.basedomain;
        if (method === 'GET') {
            return axios.get(`${this.domain}${path}`, await self.getHeaders()).then((response: any) => {
                self.return.data = response.data;
                self.return.status = response.data.status;
                if (self.return.status === 'ERROR') {
                    self.return.msg = response.data.msg;
                }
                return self.return;
            }).catch((response: any) => {
                self.return.status = 'ERROR';
                self.return.msg = response.response.msg;
                if (response.response.data.msg) {
                    self.return.msg = response.response.data.msg;
                }
                return self.return;
            });
        }

        return axios.post(`${this.domain}${path}`, postdata, await self.getHeaders()).then((response: any) => {
            self.return.data = response.data;
            self.return.status = response.data.status;
            if (self.return.status === 'ERROR') {
                self.return.msg = response.data.msg;
            }
            return self.return;
        }).catch((response: any) => {
            self.return.status = 'ERROR';
            self.return.msg = response.response.msg;
            if (response.response.data.msg) {
                self.return.msg = response.response.data.msg;
            }
            return self.return;
        });
    }
}
