
import Vue from 'vue';
import {
  mdbContainer,
  mdbNavbar,
  mdbNavbarBrand,
  mdbRow,
  mdbCol,
  mdbChat,
  mdbChatRoom,
  mdbSpinner,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import Pusher from 'pusher-js';

export default Vue.extend({
  name: 'homepage',
  components: {
    mdbContainer,
    mdbNavbar,
    mdbNavbarBrand,
    mdbRow,
    mdbCol,
    mdbChat,
    mdbChatRoom,
    mdbSpinner,
  },
  mounted() {
    document.getElementsByTagName('textarea')[0].setAttribute('placeholder', 'Votre question!');
    this.pusher = new Pusher('8d6c42bebcc407595266', {
      cluster: 'us2',
    });
    this.msgchannelname = `msgchannel_${new Date().getTime().toString()}`;
    this.pusherchannel = this.pusher.subscribe('nolinor_chat_completion');
    this.pusherchannel.bind(this.msgchannelname, (data:any) => {
      if (this.basicChat.chat[0].messages[this.basicChat.chat[0].messages.length - 1].content === '...') {
        this.basicChat.chat[0].messages[this.basicChat.chat[0].messages.length - 1].content = '';
      }
      this.basicChat.chat[0].messages[this.basicChat.chat[0].messages.length - 1].content = this.basicChat.chat[0].messages[this.basicChat.chat[0].messages.length - 1].content + data.message;
      this.$nextTick(() => {
        document.getElementsByClassName('scrollbar-light-blue')[0].scrollTop = document.getElementsByClassName('scrollbar-light-blue')[0].scrollHeight;
      });
    });
  },
  data() {
    return {
      iswaiting: false,
      msgchannelname: '' as string,
      pusherchannel: null as any,
      conversation: [] as any[],
      history: [] as any[],
      basicChat: {
        loggedUserId: 1,
        lastId: 4,
        chat: [
          {
            id: 0,
            name: 'Kira votre ressource humaine virtuelle ',
            img: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-5.jpg',
            messages: [
              {
                id: 0,
                date: new Date().toISOString(),
                content:
                    'Bonjour! Je m\'appelle Kira et je suis là pour répondre à toutes vos questions en ressources humaine. Mes connaissances se limitent seulement au guide de l\'employé donc si je suis dans l\'impossibilité de répondre à une de vos question, n\'hésitez pas à contacter un de mes collègue humain chez Nolinor.',
              },
              {
                id: 1,
                date: new Date().toISOString(),
                content:
                    'Comment puis-je vous aider aujourd\'hui?',
              },
            ],
          },
          {
            id: 1,
            name: 'Vous ',
            img: '/img/man-avatar.jpg',
            messages: [],
          },
        ] as any[],
      },
      pusher: null as any,
    };
  },
  methods: {
    sendQuestion(question:string) {
      const apicall = new Apicall('BETA2');
      apicall.call('POST', '', { history: this.history, q: question, streamchannel: this.msgchannelname }).then((response:any) => {
        this.basicChat.chat[0].messages[this.basicChat.chat[0].messages.length - 1].date = new Date().toISOString();
        this.basicChat.chat[0].messages[this.basicChat.chat[0].messages.length - 1].content = this.handleurls(response.data.content);
        this.$nextTick(() => {
          document.getElementsByClassName('scrollbar-light-blue')[0].scrollTop = document.getElementsByClassName('scrollbar-light-blue')[0].scrollHeight;
        });
        this.iswaiting = false;
      });
      this.$nextTick(() => {
        document.getElementsByClassName('scrollbar-light-blue')[0].scrollTop = document.getElementsByClassName('scrollbar-light-blue')[0].scrollHeight;
      });
    },
    handleurls(text:string):string {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replaceAll(/<\/?[^>]+(>|$)/gi, '').replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
    },
    createMessage(e:any, id:any) {
      const { content, unread, date } = e;
      return {
        id: id + 1,
        date,
        content,
        unread,
      };
    },
    sendMessage(e:any) {
      this.history = this.basicChat.chat[0].messages
          .slice(2)
          .map((m:any) => ({ t: 'AI', c: m.content, id: m.id }))
          .concat(this.basicChat.chat[1].messages.map((m:any) => ({ t: 'USER', c: m.content, id: m.id })))
          .sort((a:any, b:any) => a.id - b.id);
      let newMessage = this.createMessage(e, this.basicChat.lastId);
      this.basicChat.chat
          .find((el:any) => el.id === this.basicChat.loggedUserId)
          .messages.push(newMessage);
      this.basicChat.lastId += 1;
      newMessage = this.createMessage({ content: '...', unread: true, date: new Date().toISOString() }, this.basicChat.lastId);
      this.basicChat.chat
          .find((el:any) => el.id === 0)
          .messages.push(newMessage);
      this.basicChat.lastId += 1;
      this.sendQuestion(e.content);
      this.iswaiting = true;
    },
  },
});
